export const email = 'adrian@adrianserbanescu.com'

export const socialMedia = [
    {
        name: 'Github',
        url: 'https://github.com/adrianaris',
    },
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/in/foxinc',
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/serbanescuadi',
    }
]
